import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Typography, Button} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import classNames from 'classnames';

import Utils from '../api/api';
import theme from '../layout/theme';

const styles = {
  hide: {
    display: 'none'
  },
  error: {
    color: theme.palette.error[500]
  },
  success: {
    color: theme.palette.primary[500],
    fontSize: '125%'
  },
  container: {
    /* position: 'fixed', */
    zIndex: 10001,
    left: 0,
    right: 0,
    top: 56,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  [theme.breakpoints.up('sm')]: {
    container: {
      top: 64
    }
  },
  form: {
    width: 300,
    margin: '0 auto',
    padding: 30,
    border: 'solid 1px #eee',
    boxShadow: '0 0 15px rgba(0,0,0,0.1)',
    background: '#fff'
  },
  textField: {
    width: '100%'
  },
  textInput: {},
  formRow: {
    justifyContent: 'flex-end'
  }
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      email: '',
      username_error: false,
      email_error: false,
      error: false,
      message: '',
      status: true
    };
  }
  componentDidMount() {
    //console.log(this.props);
    // var main = document.querySelector('.main');
    // main.style.height = window.innerHeight - document
    //   .querySelector('header')
    //   .offsetHeight - document
    //   .querySelector('footer')
    //   .offsetHeight + 'px';
  }
  handleValidate(text, e) {
    var $this = this,
        obj = $this.state;

    obj[text] = e.target.value;
    var p = text + '_error';

    if(obj.username.trim()==='') obj.username_error = true;
    obj.email_error = !Utils.validateEmail(obj[text]);

    if(!obj.username_error && !obj.email_error) {
      obj.error = false;
    } else {
      obj.error = true;
    }

    this.setState(obj, () => {

    });
  }
  handleSend(e) {
    var $this = this;
    if (this.state.error) {
      return;
    } else {
      Utils._post('users/forgot-pass',$this.state,function(data){
        if(data.status==='success') {
          $this.setState({status:data.status,message:'Yêu cầu tạo mới mật khầu thành công. Vui lòng check email để thực hiện tạo mới mật khẩu.'});
        } else {
          $this.setState({status: data.status,message: 'Yêu cần tạo mới mật khầu thất bại'});
        }
      });
    }
  }
  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.container}>
        <form className={classes.form} noValidate>
          <Typography type="body2" className={classNames(this.state.status?classes.success:classes.error, this.state.message==='' && classes.hide)} >{this.state.message}</Typography>
          <Typography type="body2" className={classNames(classes.error, (!this.state.error) && classes.hide)} >Vui lòng nhập tất cả các field (*)</Typography>
          <Typography type="body2" className={classNames(classes.error, !this.state.email_error && classes.hide)} >Email sai định dạng</Typography>
          <TextField id="username" label="Username" className={classes.textField} value={this.state.username} onChange={(event) => this.handleValidate('username', event)} margin="normal" required={true} error={this.state.username_error}/>
          <TextField id="email" label="Email" className={classes.textField} type="email" margin="normal" value={this.state.email} onChange={event => this.handleValidate('email', event)} required={true} error={this.state.email_error}/>
          <FormGroup row className={classes.formRow}>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => this.handleSend(event)}>Gửi yêu cầu</Button>
          </FormGroup>
        </form>
      </div>
    );
  }
}

ForgotPassword.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(ForgotPassword);
